import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="header">
      <h1>Centro Médico Peravia</h1>
      <button className="hamburger" onClick={toggleMenu}>
        ☰
      </button>
      <nav className={`nav-menu ${isOpen ? 'open' : ''}`}>
        <Link to="/" onClick={toggleMenu}>Inicio</Link>
        <Link to="/nosotros" onClick={toggleMenu}>Nosotros</Link>
        <Link to="/especialidades" onClick={toggleMenu}>Especialidades</Link>
        <Link to="/contacto" onClick={toggleMenu}>Contacto</Link>
      </nav>
    </header>
  );
};

export default Header;
