import React from 'react';
import './Footer.css';

const Footer = () => (
  <footer className="footer">
  <div className="footer-content">
    <div className="footer-item">
      <i className="fas fa-map-marker-alt"></i>
      <p>C/ Mella #56 Baní Provincia Peravia, República Dominicana.</p>
    </div>
    <div className="footer-item">
      <i className="fas fa-phone-alt"></i>
      <p>Teléfonos: 809-522-3846</p>
      <p>Email: clinicaperavia@hotmail.com</p>
    </div>
    <div className="footer-item">
      <i className="fas fa-ambulance"></i>
      <p>Emergencias 24/7</p>
      <p>Admision: 401</p>
      <p>Enfemeria: 218</p>
      <p>Contabilidad: 219</p>
    </div>
  </div>
</footer>

);

export default Footer;
