import React from 'react';
import './Home.css';

const especialidades = [
  { nombre: "Anestesiología", img: "/images/doctor-holding-respirator.webp" },
  { nombre: "Cardiología", img:"images/doctor-showing-plastic-heart.webp"},
  { nombre: "Cirugía General", img: "images/surgical-procedure-made-by-doctor.webp" },
  { nombre: "Cirugía Plástica", img: "/images/doctor-holding-respirator.webp" },
  { nombre: "Diabetología", img: "images/doctor-showing-plastic-heart.webp" },
  // { nombre: "Endocrinología", img: "images/surgical-procedure-made-by-doctor.jpg" },
];

const estudios = [
  { nombre: "Estudios de Cardiología", img: "/images/estudiocardiologia.webp" },
  { nombre: "Estudios de Mamografía", img:"images/estudiosmamografia.webp"},
  { nombre: "Estudios de Patología", img: "images/patologia.webp" },
  { nombre: "Estudios Rayos X", img: "/images/rayosx.webp" },
  { nombre: "Estudios de Laboratiorio", img: "images/estudiolaboratorio.webp" }
  // { nombre: "Endocrinología", img: "images/surgical-procedure-made-by-doctor.jpg" },
];


const Home = () => (
  <div className="home">
    <header className="home-header">
      <p><h1>Bienvenido al Centro Médico Peravia</h1></p>
      <p>Ofrecemos atención médica de calidad en diversas especialidades.</p>
    </header>

    <section className="services">
      <h2>Nuestros Servicios</h2>

      <h3>Especialidades</h3>
      <div className="especialidades-grid">
        {especialidades.map((esp, index) => (
          <div key={index} className="especialidad-card">
            <img src={esp.img} alt={esp.nombre} />
            <h3>{esp.nombre}</h3>
          
          </div>
        ))}
      </div>
      <h3>Estudios</h3>
      <div className="especialidades-grid">
        {estudios.map((esp, index) => (
          <div key={index} className="especialidad-card">
            <img src={esp.img} alt={esp.nombre} />
            <h3>{esp.nombre}</h3>
           
          </div>
        ))}
      </div>
    </section>
    {/* <section className="services">
      <h2>Nuestros Servicios</h2>
      <div className="service-list">
        <div className="service-item">
          <h3>Consulta General</h3>
          <p>Atención primaria y chequeos de salud.</p>
        </div>
        <div className="service-item">
          <h3>Odontología</h3>
          <p>Cuida tu salud dental con nuestros especialistas.</p>
        </div>
      </div>
    </section> */}
    <section className="services">
      <h2>Seguros Médicos Aliados</h2>
      <div className="seguros-logos">
        <img src="/images/humano.jpg" alt="Humano" />
        <img src="/images/primera.jpg" alt="Primera ARS" />
        <img src="/images/mapfre.png" alt="Mapfre Salud" />
        <img src="/images/universal.jpg" alt="ARS Universal" />
        {/* <a href="#" className="ver-mas">Ver más</a> */}
      </div>
    </section>
  </div>
);

export default Home;
