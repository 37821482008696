import React from 'react';
import "./Nosotros.css";
// const HeaderComponent = () => (
//   <header>
//     <img src="logo-url" alt="Logo" />
//     <h1>Centro Médico</h1>
//   </header>
// );

const HistoriaComponent = () => (
  <section className='historia-section'>
    <h2>Nuestra Historia</h2>
    <p>
      Fundado con el compromiso de mejorar la salud de la comunidad de Peravia, el Centro Médico Peravia ha crecido a lo largo de los años para convertirse en un referente de atención médica de calidad en la región. Con un equipo de profesionales altamente capacitados y tecnología de vanguardia, nuestra institución se ha dedicado a ofrecer servicios de salud integrales y personalizados. Desde su creación, el centro ha mantenido una firme dedicación a la excelencia médica y al bienestar de cada paciente.
    </p>
    <div>
      <h2>Misión</h2>
      <p>Brindar servicios de salud de alta calidad, accesibles y personalizados, a través de un equipo humano competente y comprometido, con el objetivo de mejorar la calidad de vida de nuestra comunidad. Nos esforzamos por ofrecer atención integral basada en la ética, el respeto y la empatía, promoviendo la salud y el bienestar de cada paciente.</p>
      <h2>Visión</h2>
      <p>Ser reconocidos como el centro médico líder en Peravia, distinguiéndonos por nuestro compromiso con la innovación, la excelencia en la atención y el desarrollo continuo de nuestros profesionales. Aspiramos a convertirnos en un referente de salud en la región, ofreciendo servicios integrales y avanzados que contribuyan a una sociedad más saludable y sostenible.</p>
    </div>
  </section>

);

// const MisionVisionComponent = () => (
//   <section>
//     <h2>En que creemos</h2>
//     <div>
//       <h3>Misión</h3>
//       <p>Proveer servicios de salud de calidad...</p>
//       <h3>Visión</h3>
//       <p>Ser modelo en la provisión de servicios...</p>
//     </div>
//   </section>
// );

// const AvalesComponent = () => (
//   <section>
//     <h2>Nuestros Avales</h2>
//     <img src="image-url" alt="Avales" />
//   </section>
// );

// const EstadisticasComponent = () => (
//   <section className="estadisticas">
//     <div>
//       <h3>Más de 200</h3>
//       <p>Profesionales de la salud</p>
//     </div>
//     <div>
//       <h3>Más de 4000</h3>
//       <p>Procedimientos quirúrgicos exitosos en 2016</p>
//     </div>
//     <div>
//       <h3>Más de 800</h3>
//       <p>Nacimientos al último año</p>
//     </div>
//     <div>
//       <h3>Más de 20000</h3>
//       <p>Emergencias al año</p>
//     </div>
//     <div>
//       <h3>Más de 13000</h3>
//       <p>Hospitalizaciones</p>
//     </div>
//   </section>
// );

const App = () => (
  <div>
    {/* <HeaderComponent /> */}
    <HistoriaComponent />
    {/* <MisionVisionComponent /> */}
    {/* <AvalesComponent />
    <EstadisticasComponent /> */}
  </div>
);

export default App;
