import React from 'react';

import './Contacto.css';

function Contacto() {
  return (
    <div className="contact-container">
      <div className="contact-content">
        <div className="contact-map">
        <h2>Contactenos</h2>
          <iframe
            title="Google Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3788.53918750775!2d-70.33527432549187!3d18.276934382770037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8ea54e810150e8a3%3A0x7ec736b8b3526035!2sCl%C3%ADnica%20Peravia!5e0!3m2!1ses-419!2sdo!4v1730141224524!5m2!1ses-419!2sdo"
            width="100%"
            height="350"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
        <div className="contact-form">
        <h2>Escribanos</h2>
          <form>
            <label>Nombre Completo</label>
            <input type="text" name="name" required />

            <label>Correo Electronico</label>
            <input type="email" name="email" required />

            <label>Asunto</label>
            <input type="text" name="subject" required />

            <label>Su Mensaje</label>
            <textarea name="message" rows="4" required></textarea>

            <button type="submit">Send</button>
          </form>
          <div className="contact-info">
            <h3>Centro Médico Peravia</h3>
      
            
            <p><i className="fas fa-map-marker-alt"></i> C/ Mella #56, Esq. 16 de Agosto, Baní Provincia Peravia, Republica Dominicana</p>
            
           
            <a href="http://www.clinicaperavia.com"><i className="fas fa-solid fa-globe"></i> www.clinicaperavia.com</a>
            <p><i className="fas fa-regular fa-envelope"></i> Email: clinicaperavia@hotmail.com</p>
            <p><i className="fas fa-solid fa-phone"></i> Tel: 809-522-3846</p>
          </div>
        </div>
      </div>
      {/* <div className="contact-info">
        <h3>Centro Medico Dominico Cubano</h3>
        <p>Calle Doctor Piñeyro, Esq. Jonas Salk, Zona Universitaria</p>
        <p>Distrito Nacional, Santo Domingo, Republica Dominicana</p>
        <a href="http://www.dominicocubano.com">www.dominicocubano.com</a>
        <p>Email: info@dominicocubano.com</p>
        <p>Tel: 809.221.5501</p>
      </div> */}
    </div>
  );
}

export default Contacto;
